import {
  Seo,
  Html,
  TextContent,
} from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import React from "react";
import {
  TypographyBlock,
  PageGrid,
  PageGridItem,
  PageSection,
} from "@wsui/base";
import useError404Settings from "../../../hooks/error404";
import SearchField from "../../../wsui/components/SearchField.jsx";
import { css, useTheme } from "@emotion/react";

export default function NotFoundPage() {
  const { errordescription, errorsummary, errortitle } = useError404Settings();
  const theme = useTheme();

  return (
    <article>
      <Seo title={errortitle} />
      <PageSection>
        <PageGrid>
          <PageGridItem colspan={7}>
            <TypographyBlock>
              <h1>{errortitle}</h1>

              <TextContent elementMapping={{ p: "preamble" }}>
                {errorsummary}
              </TextContent>
              <Html>{errordescription}</Html>
            </TypographyBlock>

            <SearchField
              placeholder="Sök"
              css={css`
                margin-top: 2rem;
                border: 1px solid;
                border-radius: 6px;
                border-color: ${theme.getColor("border.300")};
              `}
            />
          </PageGridItem>
        </PageGrid>
      </PageSection>
    </article>
  );
}
